const audio = {
    namespaced: true,
    state: {
        buffer: null,
        source: null,
        audioCtx: null,
        isClickPage: false,
    },
    mutations: {
        SET_BUFFER(state, data) {
            state.buffer = data
        },
        SET_AUDIO_CTX(state, data) {
            state.audioCtx = data
        },
        SET_SOURCE(state, data) {
            state.source = data
        },
        SET_IS_CLICK_PAGE(state, data) {
            state.isClickPage = data
        },
    },
    actions: {
        initAudio({ commit }, audioUrl) {
            let audioCtx = new AudioContext()

            let request = new XMLHttpRequest()
            request.open('GET', audioUrl, true)
            request.responseType = 'arraybuffer'
            request.onload = () => {
                let audioData = request.response
                audioCtx.decodeAudioData(
                    audioData,
                    (buf) => {
                        // executes when buffer has been decoded
                        commit('SET_BUFFER', buf)
                        commit('SET_AUDIO_CTX', audioCtx)
                    },
                    (err) => {
                        console.error(`Unable to get the audio file: Error: ${err.message}`)
                    }
                )
            }
            request.send()
        },
        initSource({ state, commit }) {
            let source = state.audioCtx.createBufferSource()
            source.buffer = state.buffer
            source.connect(state.audioCtx.destination)
            commit('SET_SOURCE', source)
        },
        play({ state, dispatch }) {
            if(state.source){
                state.source.start()
            }
            dispatch('initSource')
        },
        setIsClickPage({ commit }, data) {
            commit('SET_IS_CLICK_PAGE', data)
        },
    },
}

export default audio
