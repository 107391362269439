import Stomp from 'stompjs'
import SockJS from 'sockjs-client'

export function WsClient(options) {
    let client = null

    function init() {
        const socket = new SockJS(options.url)
        client = Stomp.over(socket)
        client.debug = (str) => {
            if (!str.includes('pong pong')) {
                console.log(str)
            }
        }

        client.connect(
            {},
            () => {
                if (options.onConnect) options.onConnect()
            },
            (error) => {
                if (options.error) options.error(error)
            }
        )

        client.disconnect = () => {
            console.log('websocket disconnect')
            if (options.disconnect) options.disconnect()
        }
    }

    init()

    return client
}
