import { WsClient } from '@/utils/websocket'
import Emitter from 'tiny-emitter'

const websocket = {
    namespaced: true,
    state: {
        event: new Emitter(),
        ws: null,
        uuid: null,
        topics: [],
        connectFlag: false,
    },
    mutations: {
        SET_WEBSOCKET(state, data) {
            state.ws = data
        },
        SET_UUID(state, data) {
            state.uuid = data
        },
        SET_EVENT(state, data) {
            state.event = data
        },
        SET_TOPICS(state, data) {
            state.topics = data
        },
    },
    actions: {
        connect({ commit, dispatch }) {
            let options = {
                url: `${process.env.VUE_APP_API_URL}/dory-stream`,
                onConnect: () => dispatch('onConnect'),
                error: (err) => dispatch('onError', err),
            }

            // commit('SET_UUID', data.id)
            commit('SET_WEBSOCKET', new WsClient(options))
            commit('SET_EVENT', new Emitter())
        },
        onConnect({ state, dispatch }) {
            state.event.emit('on-ws-connect')
            if (state.connectFlag) {
                dispatch('subscrible', { topic: 'REGISTER' })
                dispatch('subscrible', { topic: 'TRANSACTION' })
                state.connectFlag = false
            }
        },
        onError({ state, dispatch }, data) {
            state.connectFlag = true
            setTimeout(() => {
                dispatch('connect')
            }, 3000)
            state.event.emit('on-error', data)
        },
        subscrible({ state, commit }, data) {
            let topics = [...state.topics]
            switch (data.topic) {
                case 'REGISTER': {
                    let newTopic = state.ws.subscribe('/topic/transactions', (message) => {
                        if (message.body == 'pong pong') return

                        let data = JSON.parse(message.body)
                        state.event.emit('on-websocket-message', data)
                    })
                    topics.push(newTopic)
                    break
                }
                case 'TRANSACTION': {
                    const body = JSON.stringify({
                        action: 'subscribe',
                        subscriptions: [
                            {
                                channel: 'transactions',
                            },
                        ],
                        id: state.uuid,
                    })
                    state.ws.send('/ws/subscribe', {}, body)
                    break
                }
            }

            commit('SET_TOPICS', topics)
        },
        disconnect({ state, commit }) {
            state.topics.forEach((topic) => {
                topic.unsubscribe()
            })
            state.ws.send({ destination: '/ws/unsubscribe', body: 'Dory' })

            state.ws.disconnect()
            state.event.emit('on-websocket-disconnect')

            commit('SET_TOPICS', [])
            commit('SET_UUID', null)
            commit('SET_WEBSOCKET', null)
            commit('SET_EVENT', null)
        },
        clearuuid({ commit }) {
            commit('SET_UUID', null)
        },
    },
}

export default websocket
