import { createStore } from 'vuex'
import audio from './modules/audio'
import user from './modules/user'
import websocket from './modules/websocket'

export default createStore({
    modules: {
        audio: audio,
        user: user,
        websocket: websocket,
    },
})
