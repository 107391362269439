<template>
    <div class="app" @click="onClickApp">
        <router-view name="header" />

        <div :class="containerClass">
            <router-view />
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    setup() {
        const fullWidthPage = ['StoreOrderDetail', 'Receipt']
        const store = useStore()
        const router = useRouter()
        const containerClass = ref('container')

        // 監聽 router
        watch(
            () => router.currentRoute.value.name,
            (toName) => {
                containerClass.value = fullWidthPage.includes(toName) ? 'container full-width-container' : 'container'
            },
            { immediate: true, deep: true }
        )

        // Init audio
        store.dispatch('audio/initAudio', require('@/assets/kashing.mp3'))

        const onClickApp = () => {
            if (!store.state.audio.isClickAudio) {
                store.dispatch('audio/initSource')
                store.dispatch('audio/setIsClickPage', true)
            }
        }

        return {
            containerClass,
            onClickApp,
        }
    },
}
</script>

<style scoped>
.app {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #28394d;
}
.header {
    width: 100%;
    height: 3rem;
}
.container {
    width: 100%;
    max-width: 768px;
    padding: 0 1.5rem;
    box-sizing: border-box;
    height: calc(100% - 3rem);
    position: absolute;
    top: 3rem;
    z-index: 0;
    overflow: scroll;
}

.full-width-container {
    max-width: initial;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container::-webkit-scrollbar {
    display: none;
}

.login-container {
    height: 100% !important;
    top: 0 !important;
}
</style>

<style>
.app {
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
.el-button--primary {
    background: linear-gradient(#d3bda2, #bfa17c);
    color: #2c2c1e;
    border: none;
}
</style>
