const user = {
    namespaced: true,
    state: {
        userInfo: {
            id: 2,
            email: 'lshproteam.merchant@gmail.com',
            name: 'lshproteam',
        },
    },
    mutations: {
        SET_USER_INFO(state, data) {
            state.userInfo = { ...state.userInfo, ...data }
        },
    },
    actions: {
        setUserInfo({ commit }, data) {
            commit('SET_USER_INFO', data)
        },
    },
}

export default user
