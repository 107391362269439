import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'Login',
        components: {
            default: () => import('@/views/Login/Login.vue'),
        },
    },
    {
        path: '/home',
        name: 'Home',
        components: {
            default: () => import('@/views/Home/Home.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '名品店家 - FAMOUS BOUTIQUE',
            },
        },
    },
    {
        path: '/order-list',
        name: 'OrderList',
        components: {
            default: () => import('@/views/OrderList/OrderList.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '交易紀錄',
            },
        },
    },
    {
        path: '/store-order-detail',
        name: 'StoreOrderDetail',
        components: {
            default: () => import('@/views/StoreOrderDetail/StoreOrderDetail.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '店家訂單明細表',
            },
        },
    },
    {
        path: '/receipt',
        name: 'Receipt',
        components: {
            default: () => import('@/views/Receipt/Receipt.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '列印收據',
            },
        },
    },
    {
        path: '/order-create',
        name: 'OrderCreate',
        components: {
            default: () => import('@/views/OrderCreate/OrderCreate.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '建立訂單',
            },
        },
    },
    {
        path: '/order-detail/:id',
        name: 'OrderDetail',
        components: {
            default: () => import('@/views/OrderDetail/OrderDetail.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '您的訂單',
            },
        },
    },
    {
        path: '/max-qrcode/:id',
        name: 'MaxQRcode',
        components: {
            default: () => import('@/views/QRcode/MaxQRcode.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '支付您的訂單 - MAX',
            },
        },
    },
    {
        path: '/max-qrcode/middleware',
        name: 'MaxQrcodeMiddleware',
        components: {
            default: () => import('@/views/QRcode/MaxQrcodeMiddleware.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '提醒',
                isShowLogout: false,
            },
        },
    },
    {
        path: '/binance-qrcode/:id',
        name: 'BinanceQRCode',
        components: {
            default: () => import('@/views/QRcode/BinanceQRCode.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '支付您的訂單 - 幣安',
            },
        },
    },
    {
        path: '/transaction-result',
        name: 'TransactionResult',
        components: {
            default: () => import('@/views/TransactionResult/TransactionResult.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '支付結果',
            },
        },
    },
    {
        path: '/bind',
        name: 'DeviceBind',
        components: {
            default: () => import('@/views/DeviceBind/DeviceBind.vue'),
            header: () => import('@/components/Header.vue'),
        },
        props: {
            header: {
                title: '裝置綁定',
            },
        },
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

export default router
